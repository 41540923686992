<template>
  <Search @search="onSearch">
    <TimelapseAdd ref="TimelapseAddModal" @info="onInfo" />
    <ListGroup title="Timelapse Generations" smallTitle="(3)" :items="timelapse" addBtn @add="onAdd" />
  </Search>
</template>

<script>
import Search from '@/containers/Search'
import ListGroup from '@/components/ListGroup'
import TimelapseAdd from './TimelapseAdd'

export default {
  name: 'TimelapseGenerations',
  components: {
    Search,
    ListGroup,
    TimelapseAdd
  },
  data () {
    return {
      querying: false,
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      count: 0,
      searchText: '',
      list: [],

      timelapse: [
        // {
        //   id: '1',
        //   url: 'https://image.shutterstock.com/image-photo/cctv-camera-on-road-artificial-260nw-1515963053.jpg',
        //   name: '우림라이온스 차로',
        //   duration: 'from 3 days ago',
        //   address: '서울특별시 금천구 가산디지털1로',
        //   logic: ['1일 마다 30초 길이의 타임랩스 영상을 생성']
        // },
        // {
        //   id: '2',
        //   url: 'img/tl1.jpg',
        //   name: 'ITX AI 연구소 입구',
        //   duration: 'from 2 months ago',
        //   address: '서울특별시 금천구 가산디지털1로 212 코오롱 디지털타워 애스턴 906호',
        //   logic: ['7일 마다 20초 길이의 타임랩스 영상을 생성', '9:00-18:00 대상 시간 한정']
        // },
        // {
        //   id: '3',
        //   url: 'https://previews.123rf.com/images/123branex/123branex1409/123branex140900035/31727943-%EA%B3%BC%EC%88%98%EC%9B%90%EC%97%90%EC%84%9C-%EC%82%AC%EA%B3%BC.jpg',
        //   name: '청송 제일 사과 농원 A21 라인',
        //   duration: 'from yesterday',
        //   address: '경상북도 청송군 청송읍 월막리 청송군산림조합',
        //   logic: ['1개월 마다 60초 길이의 타임랩스 영상을 생성', '사람이 없는 영상', '9:00-18:00 대상 시간 한정', '5월-11월 대상월 한정']
        // }
      ]
    }
  },
  mounted: function () {
    this.getTimelapses();
  },
  methods: {
    getTimelapses: function() {
      this.querying = true;
      this.$api.getTimelapses(
        (result) => {
          this.querying = false;
          this.list = result.data.list;
          this.pageTotal = result.data.page.total;
          this.pageCurrent = result.data.page.current;
          this.count = result.data.page.count;
          this.postProcess();
        },
        (error) => {
          console.error(error);
          this.querying = false;
        },
        {
          // sort: 'created_at',
          // order: 'desc',
          search: this.searchText,
          page: this.pageCurrent,
          ipp: 5
        }
      )
    },
    onSearch: function(text) {
      this.searchText = text;
      this.getTimelapses();
    },
    onAdd: function() {
      this.$refs.TimelapseAddModal.open();
    },
    onInfo: function(payload) {
      // console.log(payload);
      this.$api.AddTimelapse((result) => {
        console.log(result.data);
        this.getTimelapses();
      }, (error) => {
        console.error(error);
      }, payload);
    },
    postProcess: function() {
      let i = 0;
      for (let el of this.list) {
        let now = new Date();
        let created = new Date(el.created_at);
        let diff = parseInt((now - created)/1000);
        if (diff < 60) {
          el.duration = 'just before';
        } else if (diff < 3600) {
          el.duration = `from ${parseInt(diff / 60)} minutes ago`;
        } else if (diff < 86400) {
          el.duration = `from ${parseInt(diff / 3600)} hours ago`;
        } else if (diff < 86400 * 7) {
          el.duration = `from ${parseInt(diff / 86400)} days ago`;
        } else if (diff < 86400 * 30) {
          el.duration = `from ${parseInt(diff / (86400*7))} weeks ago`;
        } else {
          el.duration = `from ${parseInt(diff / (86400*30))} months ago`;
        }

        // Generates a {{ out_length }}-second timelapse video every {{ in_cycle }} {{ SelectedUnit }}
        el.logic = []

        const u = this.$resource.TIMELAPSE_UNITS.find(e => e.value === el.in_unit);
        const gen_logic = `Generates a ${el.out_length}-second timelapse video every ${el.in_cycle} ${u.label}`;
        el.logic.push(gen_logic);
        if (el.stime !== el.etime) {
          const fh = parseInt(el.stime / 100);
          const fm = parseInt(el.stime % 100);
          const th = parseInt(el.etime / 100);
          const tm = parseInt(el.etime % 100);
          const time_logic = `Active during ${fh}:${fm}-${th}:${tm}`;
          el.logic.push(time_logic);
        }
      }
      this.timelapse = this.list;
    }
  }
}
</script>
