<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    title="New Timelapse"
    size="lg"
    color="primary"
  >
    <CAlert :show.sync="dismissCountDown" color="danger" fade>{{ notification }}</CAlert>
    <CCard v-if="step === 0">
      <CCardHeader>
        Device Selection (1/{{totalStep}})
      </CCardHeader>
      <CCardBody>
        <Search PlaceHolder="Device Search" @search="onSearch">
          <CListGroup>
            <CListGroupItem tag="button" v-for="dev in deviceList" :key="`device-list-${dev.id}`" @click="onSelectDevice(dev.id)">
              <div>{{ dev.name }}  <small>{{ dev.mac }}</small></div>
              <!-- <div><small>{{ dev.branch_name}} / {{ dev.section_name }}</small></div> -->
            </CListGroupItem>
          </CListGroup>
        </Search>
      </CCardBody>
    </CCard>
    <CCard v-else-if="step === 1">
      <CCardHeader>
        Channel Selection (2/{{totalStep}})
      </CCardHeader>
      <CCardBody>
        <CInput
          label="Device"
          horizontal
          :value="SelectedDevice.name"
          :description="SelectedDeviceDescription"
          disabled
        >
          <template #append>
            <CButton color="primary" size="sm" @click="onPrev">
              <CIcon name="cil-pencil" />
            </CButton>
          </template>
        </CInput>
        <CSelect
          label="Channel"
          horizontal
          :options="SelectedDeviceChannels"
          :value.sync="ch"
          @change="onSelectChannel"
        />
      </CCardBody>
    </CCard>
    <CCard v-else-if="step === 2">
      <CCardHeader>
        Video Generation Setup (3/{{totalStep}})
      </CCardHeader>
      <CCardBody>
        <CInput
          label="Name"
          horizontal
          :value.sync="name"
        >
          <template #append></template>
        </CInput>
        <CInput
          label="Cycle"
          horizontal
          type="number"
          :value.sync="in_cycle"
        >
          <template #append>
            <CDropdown
              :togglerText="SelectedUnit"
              color="secondary"
            >
              <CDropdownItem
                v-for="unit in $resource.TIMELAPSE_UNITS"
                :key="`timelapse-unit-selection-${unit.value}`"
                @click="in_unit=unit.value">{{ unit.label }}
              </CDropdownItem>
            </CDropdown>
          </template>
        </CInput>
        <CInput
          label="Video Length"
          horizontal
          append="Sec(s)"
          type="number"
          :value.sync="out_length"
        ></CInput>
      </CCardBody>
      <CCardFooter>
        <small>Generates a {{ out_length }}-second timelapse video every {{ in_cycle }} {{ SelectedUnit }}</small>
      </CCardFooter>
    </CCard>
    <CCard v-else-if="step === 3">
      <CCardHeader>
        Condition Setup (4/{{totalStep}})
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="3">
            Active Time
          </CCol>
          <CCol sm="9">
            <CRow>
              <CInputCheckbox class="mb-3" label="Active always" :checked.sync="activeAlways" @change="onChangeTime"></CInputCheckbox>
            </CRow>
            <CRow>
              <CInput class="mr-1" type="number" :value.sync="fh" min="0" max="23" :disabled="activeAlways" description="from" @change="onChangeTime" @blur="onChangeTime" />
              <CInput class="mr-3" type="number" :value.sync="fm" min="0" max="59" :disabled="activeAlways" @change="onChangeTime" @blur="onChangeTime" />
              <CInput class="mr-1" type="number" :value.sync="th" min="0" max="23" :disabled="activeAlways" description="to" @change="onChangeTime" @blur="onChangeTime" />
              <CInput type="number" :value.sync="tm" min="0" max="59" :disabled="activeAlways" @change="onChangeTime" @blur="onChangeTime" />
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <small v-if="activeAlways || stime === etime">There will be no time filtering</small>
        <small v-else>{{`Active during ${fh}:${fm}-${th}:${tm}`}}</small>
      </CCardFooter>
    </CCard>
    <template #header>
      <h6 class="modal-title">New Timelapse</h6>
      <CButtonClose @click="show = false" class="text-white"/>
    </template>
    <template #footer>
      <CButton v-if="step === 0 || step === 1" @click="onCancel" color="secondary">Cancel</CButton>
      <CButton v-if="step === 2 || step === 3" @click="onPrev" color="secondary">Prev</CButton>
      <CButton v-if="step === 1 || step === 2" @click="onNext" color="primary">Next</CButton>
      <CButton v-if="step === 3" @click="onSubmit" color="primary">Submit</CButton>
    </template>
  </CModal>
</template>

<script>
import utility from '@/utility'
import Search from '@/containers/Search'

export default {
  name: 'TimelapseAdd',
  components: {
    Search
  },
  data() {
    return {
      show: false,

      name: null,
      device: null,
      ch: 1,
      in_cycle: 1,
      in_unit: 86400,
      out_length: 30,
      stime: 0,
      etime: 0,

      activeAlways: true,
      fh: '00',
      fm: '00',
      th: '00',
      tm: '00',

      querying: false,
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      count: 0,
      searchText: '',
      deviceList: [],

      notification: '',
      dismissCountDown: 0,
      step: 0,

      totalStep: 4
    }
  },
  computed: {
    SelectedDevice() {
      const d = this.deviceList.find(el => el.id === this.device);
      if (d) return d;
      return {
        name:'',
        mac:''
      };
    },
    SelectedDeviceChannels() {
      const d = this.deviceList.find(el => el.id === this.device);
      if (d) {
        const rtn = utility.Range(1, +d.max_ch+1, 1);
        return rtn;
      }
      return [1];
    },
    SelectedDeviceDescription() {
      const d = this.deviceList.find(el => el.id === this.device);
      let desc = '';
      if (d) {
        desc = d.mac;
        if (d.branch_name) {
          desc = `${desc} / ${d.branch_name}`;
        }
        if (d.section_name) {
          desc = `${desc} / ${d.section_name}`;
        }
      }
      return desc;
    },
    SelectedUnit() {
      const u = this.$resource.TIMELAPSE_UNITS.find(el => el.value === this.in_unit);
      if (u) {
        return u.label;
      }
      return 'day(s)'
    }
  },
  methods: {
    open() {
      this.initData();
      this.show = true;
      this.getDevices();
    },
    initData: function() {
      this.name = null;
      this.device = null;
      this.ch = 1;
      this.in_cycle = 1;
      this.in_unit = 86400;
      this.out_length = 30;
      this.stime = 0;
      this.etime = 0;
      this.activeAlways = true;
      this.fh = '00';
      this.fm = '00';
      this.th = '00';
      this.tm = '00';
      this.querying = false;
      this.pageTotal = 1;
      this.pageCurrent = 1;
      this.pageDirectSet = 1;
      this.count = 0;
      this.searchText = '';
      this.deviceList = [];
      this.notification = '';
      this.dismissCountDown = 0;
      this.step = 0;
    },
    getDevices: function() {
      this.querying = true;
      this.$api.getDevices(
        (result) => {
          this.querying = false;
          this.deviceList = result.data.list;
          this.pageTotal = result.data.page.total;
          this.pageCurrent = result.data.page.current;
          this.count = result.data.page.count;
        },
        (error) => {
          console.error(error);
          this.querying = false;
        },
        {
          // sort: 'created_at',
          // order: 'desc',
          search: this.searchText,
          page: this.pageCurrent
        }
      )
    },
    onSearch(data) {
      this.searchText = data;
      this.getDevices();
    },
    onSelectDevice(id) {
      this.device = id;
      this.ch = 1;
      this.step = 1;
      this.name = `${this.SelectedDevice.name} CH${this.ch} ${utility.GetDateTimeStr('$yyyy$mm$dd_$HH$MM$ss')}`
    },
    onSelectChannel() {
      this.name = `${this.SelectedDevice.name} CH${this.ch} ${utility.GetDateTimeStr('$yyyy$mm$dd_$HH$MM$ss')}`
    },
    onChangeTime() {
      if (this.activeAlways) {
        this.fh = '00';
        this.fm = '00';
        this.th = '00';
        this.tm = '00';
      }
      this.fh = '' + this.fh;
      if (this.fh.length < 2) this.fh = '0' + this.fh;
      this.fm = '' + this.fm;
      if (this.fm.length < 2) this.fm = '0' + this.fm;
      this.th = '' + this.th;
      if (this.th.length < 2) this.th = '0' + this.th;
      this.tm = '' + this.tm;
      if (this.tm.length < 2) this.tm = '0' + this.tm;

      this.stime = Number(this.fh + this.fm);
      this.etime = Number(this.th + this.tm);
    },

    validate() {
      if (this.step === 1) {
        if (!this.device) {
          this.notification = 'Device is required';
          this.dismissCountDown = 3;
          return false;
        }
        if (!this.SelectedDevice) {
          this.notification = 'There is no such device';
          this.dismissCountDown = 3;
          return false;
        }
        if (!this.ch) {
          this.notification = 'Channel is required';
          this.dismissCountDown = 3;
          return false;
        }
        if (this.ch < 1 || this.ch > this.SelectedDevice.max_ch) {
          this.notification = 'Wrong channel number'
          this.dismissCountDown = 3;
          return false;
        }
      } else if (this.step === 2) {
        if (!this.name) {
          this.notification = 'Name is required';
          this.dismissCountDown = 3;
          return false;
        }
        if (this.name.length < 4) {
          this.notification = 'Name is too short';
          this.dismissCountDown = 3;
          return false;
        }
        if (this.in_cycle * this.in_unit < this.out_length) {
          this.notification = 'Too short timelapse cycle'
          this.dismissCountDown = 3;
          return false;
        }
        if (this.in_cycle * this.in_unit > 12*2592000) {
          this.notification = 'Too long timelapse cycle'
          this.dismissCountDown = 3;
          return false;
        }
      }
      return true;
    },
    onCancel() {
      this.show = false;
      this.initData();
    },
    onNext() {
      if (!this.validate()) {
        return;
      }
      this.step++;
    },
    onPrev() {
      this.step--;
    },
    onSubmit() {
      this.show = false;
      const payload = {
        device: this.device,
        ch: this.ch,
        name: this.name,
        in_cycle: this.in_cycle,
        in_unit: this.in_unit,
        out_length: this.out_length,
        stime: this.stime,
        etime: this.etime
      };
      this.$emit('info', payload);
      this.initData();
    }
  }
}
</script>